/* tslint:disable */
/* eslint-disable */
/**
 * API Documentation
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Video
 */
export interface Video {
    /**
     * 
     * @type {string}
     * @memberof Video
     */
    location?: string;
    /**
     * 
     * @type {string}
     * @memberof Video
     */
    mediaType?: string;
    /**
     * 
     * @type {string}
     * @memberof Video
     */
    assetHash?: string;
    /**
     * 
     * @type {string}
     * @memberof Video
     */
    title?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Video
     */
    isInterview?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof Video
     */
    authorCodes?: Array<string>;
}

/**
 * Check if a given object implements the Video interface.
 */
export function instanceOfVideo(value: object): value is Video {
    return true;
}

export function VideoFromJSON(json: any): Video {
    return VideoFromJSONTyped(json, false);
}

export function VideoFromJSONTyped(json: any, ignoreDiscriminator: boolean): Video {
    if (json == null) {
        return json;
    }
    return {
        
        'location': json['location'] == null ? undefined : json['location'],
        'mediaType': json['media_type'] == null ? undefined : json['media_type'],
        'assetHash': json['asset_hash'] == null ? undefined : json['asset_hash'],
        'title': json['title'] == null ? undefined : json['title'],
        'isInterview': json['is_interview'] == null ? undefined : json['is_interview'],
        'authorCodes': json['author_codes'] == null ? undefined : json['author_codes'],
    };
}

  export function VideoToJSON(json: any): Video {
      return VideoToJSONTyped(json, false);
  }

  export function VideoToJSONTyped(value?: Video | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'location': value['location'],
        'media_type': value['mediaType'],
        'asset_hash': value['assetHash'],
        'title': value['title'],
        'is_interview': value['isInterview'],
        'author_codes': value['authorCodes'],
    };
}

