import React from 'react';

// Custom Left Arrow Component (similar look to default)
export const CustomLeftArrow = ({ onClick }: { onClick?: () => void }) => {
  return (
    <button
      aria-label="Go to previous slide"
      className="custom-carousel-arrow"
      onClick={onClick}
      style={{ left: '10px' }} // Position from the left
    >
      <svg width="24" height="24" /* SVG content here */>
        <polyline
          points="15 18 9 12 15 6"
          fill="none"
          stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </button>
  );
};

// Custom Right Arrow Component (similar look to default)
export const CustomRightArrow = ({ onClick }: { onClick?: () => void }) => {
  return (
    <button
      aria-label="Go to next slide"
      className="custom-carousel-arrow"
      onClick={onClick}
      style={{ right: '10px' }} // Position from the right
    >
      <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <polyline
          points="9 6 15 12 9 18"
          fill="none"
          stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </button>
  );
};
