/* tslint:disable */
/* eslint-disable */
/**
 * API Documentation
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ApiAssetsExternalExternalIdGet200ResponseAuthorsInner } from './ApiAssetsExternalExternalIdGet200ResponseAuthorsInner';
import {
    ApiAssetsExternalExternalIdGet200ResponseAuthorsInnerFromJSON,
    ApiAssetsExternalExternalIdGet200ResponseAuthorsInnerFromJSONTyped,
    ApiAssetsExternalExternalIdGet200ResponseAuthorsInnerToJSON,
    ApiAssetsExternalExternalIdGet200ResponseAuthorsInnerToJSONTyped,
} from './ApiAssetsExternalExternalIdGet200ResponseAuthorsInner';

/**
 * 
 * @export
 * @interface ApiAssetsYoutubeExternalIdGet200Response
 */
export interface ApiAssetsYoutubeExternalIdGet200Response {
    /**
     * The hash of the asset.
     * @type {string}
     * @memberof ApiAssetsYoutubeExternalIdGet200Response
     */
    hash?: string;
    /**
     * The quality of the video (e.g., "360p").
     * @type {string}
     * @memberof ApiAssetsYoutubeExternalIdGet200Response
     */
    quality?: string;
    /**
     * The format of the video (e.g., "mp4", "webm").
     * @type {string}
     * @memberof ApiAssetsYoutubeExternalIdGet200Response
     */
    videoFormat?: string;
    /**
     * The size of the asset in bytes.
     * @type {number}
     * @memberof ApiAssetsYoutubeExternalIdGet200Response
     */
    contentLength?: number;
    /**
     * A list of authors associated with the asset.
     * @type {Array<ApiAssetsExternalExternalIdGet200ResponseAuthorsInner>}
     * @memberof ApiAssetsYoutubeExternalIdGet200Response
     */
    authors?: Array<ApiAssetsExternalExternalIdGet200ResponseAuthorsInner>;
}

/**
 * Check if a given object implements the ApiAssetsYoutubeExternalIdGet200Response interface.
 */
export function instanceOfApiAssetsYoutubeExternalIdGet200Response(value: object): value is ApiAssetsYoutubeExternalIdGet200Response {
    return true;
}

export function ApiAssetsYoutubeExternalIdGet200ResponseFromJSON(json: any): ApiAssetsYoutubeExternalIdGet200Response {
    return ApiAssetsYoutubeExternalIdGet200ResponseFromJSONTyped(json, false);
}

export function ApiAssetsYoutubeExternalIdGet200ResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApiAssetsYoutubeExternalIdGet200Response {
    if (json == null) {
        return json;
    }
    return {
        
        'hash': json['hash'] == null ? undefined : json['hash'],
        'quality': json['quality'] == null ? undefined : json['quality'],
        'videoFormat': json['video_format'] == null ? undefined : json['video_format'],
        'contentLength': json['content_length'] == null ? undefined : json['content_length'],
        'authors': json['authors'] == null ? undefined : ((json['authors'] as Array<any>).map(ApiAssetsExternalExternalIdGet200ResponseAuthorsInnerFromJSON)),
    };
}

  export function ApiAssetsYoutubeExternalIdGet200ResponseToJSON(json: any): ApiAssetsYoutubeExternalIdGet200Response {
      return ApiAssetsYoutubeExternalIdGet200ResponseToJSONTyped(json, false);
  }

  export function ApiAssetsYoutubeExternalIdGet200ResponseToJSONTyped(value?: ApiAssetsYoutubeExternalIdGet200Response | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'hash': value['hash'],
        'quality': value['quality'],
        'video_format': value['videoFormat'],
        'content_length': value['contentLength'],
        'authors': value['authors'] == null ? undefined : ((value['authors'] as Array<any>).map(ApiAssetsExternalExternalIdGet200ResponseAuthorsInnerToJSON)),
    };
}

