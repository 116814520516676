/* tslint:disable */
/* eslint-disable */
/**
 * API Documentation
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ApiTweetsIdSignaturesGet200ResponseInnerAssetSignaturesInner } from './ApiTweetsIdSignaturesGet200ResponseInnerAssetSignaturesInner';
import {
    ApiTweetsIdSignaturesGet200ResponseInnerAssetSignaturesInnerFromJSON,
    ApiTweetsIdSignaturesGet200ResponseInnerAssetSignaturesInnerFromJSONTyped,
    ApiTweetsIdSignaturesGet200ResponseInnerAssetSignaturesInnerToJSON,
    ApiTweetsIdSignaturesGet200ResponseInnerAssetSignaturesInnerToJSONTyped,
} from './ApiTweetsIdSignaturesGet200ResponseInnerAssetSignaturesInner';

/**
 * 
 * @export
 * @interface ApiTweetsIdSignaturesGet200ResponseInner
 */
export interface ApiTweetsIdSignaturesGet200ResponseInner {
    /**
     * The hash of the signed asset.
     * @type {string}
     * @memberof ApiTweetsIdSignaturesGet200ResponseInner
     */
    assetHash?: string;
    /**
     * The type of the asset.
     * @type {string}
     * @memberof ApiTweetsIdSignaturesGet200ResponseInner
     */
    assetType?: string;
    /**
     * The URL of the asset.
     * @type {string}
     * @memberof ApiTweetsIdSignaturesGet200ResponseInner
     */
    assetUrl?: string;
    /**
     * 
     * @type {Array<ApiTweetsIdSignaturesGet200ResponseInnerAssetSignaturesInner>}
     * @memberof ApiTweetsIdSignaturesGet200ResponseInner
     */
    assetSignatures?: Array<ApiTweetsIdSignaturesGet200ResponseInnerAssetSignaturesInner>;
}

/**
 * Check if a given object implements the ApiTweetsIdSignaturesGet200ResponseInner interface.
 */
export function instanceOfApiTweetsIdSignaturesGet200ResponseInner(value: object): value is ApiTweetsIdSignaturesGet200ResponseInner {
    return true;
}

export function ApiTweetsIdSignaturesGet200ResponseInnerFromJSON(json: any): ApiTweetsIdSignaturesGet200ResponseInner {
    return ApiTweetsIdSignaturesGet200ResponseInnerFromJSONTyped(json, false);
}

export function ApiTweetsIdSignaturesGet200ResponseInnerFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApiTweetsIdSignaturesGet200ResponseInner {
    if (json == null) {
        return json;
    }
    return {
        
        'assetHash': json['asset_hash'] == null ? undefined : json['asset_hash'],
        'assetType': json['asset_type'] == null ? undefined : json['asset_type'],
        'assetUrl': json['asset_url'] == null ? undefined : json['asset_url'],
        'assetSignatures': json['asset_signatures'] == null ? undefined : ((json['asset_signatures'] as Array<any>).map(ApiTweetsIdSignaturesGet200ResponseInnerAssetSignaturesInnerFromJSON)),
    };
}

  export function ApiTweetsIdSignaturesGet200ResponseInnerToJSON(json: any): ApiTweetsIdSignaturesGet200ResponseInner {
      return ApiTweetsIdSignaturesGet200ResponseInnerToJSONTyped(json, false);
  }

  export function ApiTweetsIdSignaturesGet200ResponseInnerToJSONTyped(value?: ApiTweetsIdSignaturesGet200ResponseInner | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'asset_hash': value['assetHash'],
        'asset_type': value['assetType'],
        'asset_url': value['assetUrl'],
        'asset_signatures': value['assetSignatures'] == null ? undefined : ((value['assetSignatures'] as Array<any>).map(ApiTweetsIdSignaturesGet200ResponseInnerAssetSignaturesInnerToJSON)),
    };
}

