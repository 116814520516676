/* tslint:disable */
/* eslint-disable */
/**
 * API Documentation
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { SocialMediaAccount } from './SocialMediaAccount';
import {
    SocialMediaAccountFromJSON,
    SocialMediaAccountFromJSONTyped,
    SocialMediaAccountToJSON,
    SocialMediaAccountToJSONTyped,
} from './SocialMediaAccount';

/**
 * 
 * @export
 * @interface ApiPeopleCodeSocialMediaGet200Response
 */
export interface ApiPeopleCodeSocialMediaGet200Response {
    /**
     * 
     * @type {Array<SocialMediaAccount>}
     * @memberof ApiPeopleCodeSocialMediaGet200Response
     */
    socialMedia?: Array<SocialMediaAccount>;
}

/**
 * Check if a given object implements the ApiPeopleCodeSocialMediaGet200Response interface.
 */
export function instanceOfApiPeopleCodeSocialMediaGet200Response(value: object): value is ApiPeopleCodeSocialMediaGet200Response {
    return true;
}

export function ApiPeopleCodeSocialMediaGet200ResponseFromJSON(json: any): ApiPeopleCodeSocialMediaGet200Response {
    return ApiPeopleCodeSocialMediaGet200ResponseFromJSONTyped(json, false);
}

export function ApiPeopleCodeSocialMediaGet200ResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApiPeopleCodeSocialMediaGet200Response {
    if (json == null) {
        return json;
    }
    return {
        
        'socialMedia': json['social_media'] == null ? undefined : ((json['social_media'] as Array<any>).map(SocialMediaAccountFromJSON)),
    };
}

  export function ApiPeopleCodeSocialMediaGet200ResponseToJSON(json: any): ApiPeopleCodeSocialMediaGet200Response {
      return ApiPeopleCodeSocialMediaGet200ResponseToJSONTyped(json, false);
  }

  export function ApiPeopleCodeSocialMediaGet200ResponseToJSONTyped(value?: ApiPeopleCodeSocialMediaGet200Response | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'social_media': value['socialMedia'] == null ? undefined : ((value['socialMedia'] as Array<any>).map(SocialMediaAccountToJSON)),
    };
}

