/* tslint:disable */
/* eslint-disable */
/**
 * API Documentation
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ApiMyvouchingUrlsPost200Response
 */
export interface ApiMyvouchingUrlsPost200Response {
    /**
     * 
     * @type {string}
     * @memberof ApiMyvouchingUrlsPost200Response
     */
    message?: string;
}

/**
 * Check if a given object implements the ApiMyvouchingUrlsPost200Response interface.
 */
export function instanceOfApiMyvouchingUrlsPost200Response(value: object): value is ApiMyvouchingUrlsPost200Response {
    return true;
}

export function ApiMyvouchingUrlsPost200ResponseFromJSON(json: any): ApiMyvouchingUrlsPost200Response {
    return ApiMyvouchingUrlsPost200ResponseFromJSONTyped(json, false);
}

export function ApiMyvouchingUrlsPost200ResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApiMyvouchingUrlsPost200Response {
    if (json == null) {
        return json;
    }
    return {
        
        'message': json['message'] == null ? undefined : json['message'],
    };
}

  export function ApiMyvouchingUrlsPost200ResponseToJSON(json: any): ApiMyvouchingUrlsPost200Response {
      return ApiMyvouchingUrlsPost200ResponseToJSONTyped(json, false);
  }

  export function ApiMyvouchingUrlsPost200ResponseToJSONTyped(value?: ApiMyvouchingUrlsPost200Response | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'message': value['message'],
    };
}

