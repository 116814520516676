/* tslint:disable */
/* eslint-disable */
/**
 * API Documentation
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ApiAssetsHashClaimsGet200ResponseAssetClaimsInner
 */
export interface ApiAssetsHashClaimsGet200ResponseAssetClaimsInner {
    /**
     * The code representing the author of the claim.
     * @type {string}
     * @memberof ApiAssetsHashClaimsGet200ResponseAssetClaimsInner
     */
    authorCode?: string;
    /**
     * First name of the author.
     * @type {string}
     * @memberof ApiAssetsHashClaimsGet200ResponseAssetClaimsInner
     */
    firstName?: string;
    /**
     * Last name of the author.
     * @type {string}
     * @memberof ApiAssetsHashClaimsGet200ResponseAssetClaimsInner
     */
    lastName?: string;
    /**
     * The public key associated with the author code.
     * @type {string}
     * @memberof ApiAssetsHashClaimsGet200ResponseAssetClaimsInner
     */
    publicKey?: string;
    /**
     * A generic JSON object representing the claim.
     * @type {{ [key: string]: any; }}
     * @memberof ApiAssetsHashClaimsGet200ResponseAssetClaimsInner
     */
    claim?: { [key: string]: any; };
    /**
     * The signature associated with the claim.
     * @type {string}
     * @memberof ApiAssetsHashClaimsGet200ResponseAssetClaimsInner
     */
    claimSignature?: string;
}

/**
 * Check if a given object implements the ApiAssetsHashClaimsGet200ResponseAssetClaimsInner interface.
 */
export function instanceOfApiAssetsHashClaimsGet200ResponseAssetClaimsInner(value: object): value is ApiAssetsHashClaimsGet200ResponseAssetClaimsInner {
    return true;
}

export function ApiAssetsHashClaimsGet200ResponseAssetClaimsInnerFromJSON(json: any): ApiAssetsHashClaimsGet200ResponseAssetClaimsInner {
    return ApiAssetsHashClaimsGet200ResponseAssetClaimsInnerFromJSONTyped(json, false);
}

export function ApiAssetsHashClaimsGet200ResponseAssetClaimsInnerFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApiAssetsHashClaimsGet200ResponseAssetClaimsInner {
    if (json == null) {
        return json;
    }
    return {
        
        'authorCode': json['author_code'] == null ? undefined : json['author_code'],
        'firstName': json['first_name'] == null ? undefined : json['first_name'],
        'lastName': json['last_name'] == null ? undefined : json['last_name'],
        'publicKey': json['public_key'] == null ? undefined : json['public_key'],
        'claim': json['claim'] == null ? undefined : json['claim'],
        'claimSignature': json['claim_signature'] == null ? undefined : json['claim_signature'],
    };
}

  export function ApiAssetsHashClaimsGet200ResponseAssetClaimsInnerToJSON(json: any): ApiAssetsHashClaimsGet200ResponseAssetClaimsInner {
      return ApiAssetsHashClaimsGet200ResponseAssetClaimsInnerToJSONTyped(json, false);
  }

  export function ApiAssetsHashClaimsGet200ResponseAssetClaimsInnerToJSONTyped(value?: ApiAssetsHashClaimsGet200ResponseAssetClaimsInner | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'author_code': value['authorCode'],
        'first_name': value['firstName'],
        'last_name': value['lastName'],
        'public_key': value['publicKey'],
        'claim': value['claim'],
        'claim_signature': value['claimSignature'],
    };
}

