/* tslint:disable */
/* eslint-disable */
/**
 * API Documentation
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ApiInstagramEntryTypeIdSignaturesGet200ResponseInnerAssetSignaturesInner } from './ApiInstagramEntryTypeIdSignaturesGet200ResponseInnerAssetSignaturesInner';
import {
    ApiInstagramEntryTypeIdSignaturesGet200ResponseInnerAssetSignaturesInnerFromJSON,
    ApiInstagramEntryTypeIdSignaturesGet200ResponseInnerAssetSignaturesInnerFromJSONTyped,
    ApiInstagramEntryTypeIdSignaturesGet200ResponseInnerAssetSignaturesInnerToJSON,
    ApiInstagramEntryTypeIdSignaturesGet200ResponseInnerAssetSignaturesInnerToJSONTyped,
} from './ApiInstagramEntryTypeIdSignaturesGet200ResponseInnerAssetSignaturesInner';

/**
 * 
 * @export
 * @interface ApiInstagramEntryTypeIdSignaturesGet200ResponseInner
 */
export interface ApiInstagramEntryTypeIdSignaturesGet200ResponseInner {
    /**
     * The hash of the signed asset.
     * @type {string}
     * @memberof ApiInstagramEntryTypeIdSignaturesGet200ResponseInner
     */
    assetHash?: string;
    /**
     * The type of the asset.
     * @type {string}
     * @memberof ApiInstagramEntryTypeIdSignaturesGet200ResponseInner
     */
    assetType?: string;
    /**
     * The URL of the asset.
     * @type {string}
     * @memberof ApiInstagramEntryTypeIdSignaturesGet200ResponseInner
     */
    assetUrl?: string;
    /**
     * 
     * @type {Array<ApiInstagramEntryTypeIdSignaturesGet200ResponseInnerAssetSignaturesInner>}
     * @memberof ApiInstagramEntryTypeIdSignaturesGet200ResponseInner
     */
    assetSignatures?: Array<ApiInstagramEntryTypeIdSignaturesGet200ResponseInnerAssetSignaturesInner>;
}

/**
 * Check if a given object implements the ApiInstagramEntryTypeIdSignaturesGet200ResponseInner interface.
 */
export function instanceOfApiInstagramEntryTypeIdSignaturesGet200ResponseInner(value: object): value is ApiInstagramEntryTypeIdSignaturesGet200ResponseInner {
    return true;
}

export function ApiInstagramEntryTypeIdSignaturesGet200ResponseInnerFromJSON(json: any): ApiInstagramEntryTypeIdSignaturesGet200ResponseInner {
    return ApiInstagramEntryTypeIdSignaturesGet200ResponseInnerFromJSONTyped(json, false);
}

export function ApiInstagramEntryTypeIdSignaturesGet200ResponseInnerFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApiInstagramEntryTypeIdSignaturesGet200ResponseInner {
    if (json == null) {
        return json;
    }
    return {
        
        'assetHash': json['asset_hash'] == null ? undefined : json['asset_hash'],
        'assetType': json['asset_type'] == null ? undefined : json['asset_type'],
        'assetUrl': json['asset_url'] == null ? undefined : json['asset_url'],
        'assetSignatures': json['asset_signatures'] == null ? undefined : ((json['asset_signatures'] as Array<any>).map(ApiInstagramEntryTypeIdSignaturesGet200ResponseInnerAssetSignaturesInnerFromJSON)),
    };
}

  export function ApiInstagramEntryTypeIdSignaturesGet200ResponseInnerToJSON(json: any): ApiInstagramEntryTypeIdSignaturesGet200ResponseInner {
      return ApiInstagramEntryTypeIdSignaturesGet200ResponseInnerToJSONTyped(json, false);
  }

  export function ApiInstagramEntryTypeIdSignaturesGet200ResponseInnerToJSONTyped(value?: ApiInstagramEntryTypeIdSignaturesGet200ResponseInner | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'asset_hash': value['assetHash'],
        'asset_type': value['assetType'],
        'asset_url': value['assetUrl'],
        'asset_signatures': value['assetSignatures'] == null ? undefined : ((value['assetSignatures'] as Array<any>).map(ApiInstagramEntryTypeIdSignaturesGet200ResponseInnerAssetSignaturesInnerToJSON)),
    };
}

