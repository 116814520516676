/* tslint:disable */
/* eslint-disable */
/**
 * API Documentation
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ApiGrantPostRequest
 */
export interface ApiGrantPostRequest {
    /**
     * The ID of the submission to which the grant code will be applied.
     * @type {string}
     * @memberof ApiGrantPostRequest
     */
    submissionId?: string;
    /**
     * The grant code to be applied.
     * @type {string}
     * @memberof ApiGrantPostRequest
     */
    grantCode?: string;
}

/**
 * Check if a given object implements the ApiGrantPostRequest interface.
 */
export function instanceOfApiGrantPostRequest(value: object): value is ApiGrantPostRequest {
    return true;
}

export function ApiGrantPostRequestFromJSON(json: any): ApiGrantPostRequest {
    return ApiGrantPostRequestFromJSONTyped(json, false);
}

export function ApiGrantPostRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApiGrantPostRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'submissionId': json['submissionId'] == null ? undefined : json['submissionId'],
        'grantCode': json['grantCode'] == null ? undefined : json['grantCode'],
    };
}

  export function ApiGrantPostRequestToJSON(json: any): ApiGrantPostRequest {
      return ApiGrantPostRequestToJSONTyped(json, false);
  }

  export function ApiGrantPostRequestToJSONTyped(value?: ApiGrantPostRequest | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'submissionId': value['submissionId'],
        'grantCode': value['grantCode'],
    };
}

