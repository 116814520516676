/* tslint:disable */
/* eslint-disable */
/**
 * API Documentation
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ApiAssetsExternalExternalIdGet200ResponseAuthorsInner } from './ApiAssetsExternalExternalIdGet200ResponseAuthorsInner';
import {
    ApiAssetsExternalExternalIdGet200ResponseAuthorsInnerFromJSON,
    ApiAssetsExternalExternalIdGet200ResponseAuthorsInnerFromJSONTyped,
    ApiAssetsExternalExternalIdGet200ResponseAuthorsInnerToJSON,
    ApiAssetsExternalExternalIdGet200ResponseAuthorsInnerToJSONTyped,
} from './ApiAssetsExternalExternalIdGet200ResponseAuthorsInner';

/**
 * 
 * @export
 * @interface ApiAssetsExternalExternalIdGet200Response
 */
export interface ApiAssetsExternalExternalIdGet200Response {
    /**
     * The URL of the asset associated with the external ID.
     * @type {string}
     * @memberof ApiAssetsExternalExternalIdGet200Response
     */
    url?: string;
    /**
     * The hash of the asset.
     * @type {string}
     * @memberof ApiAssetsExternalExternalIdGet200Response
     */
    hash?: string;
    /**
     * A list of authors associated with the asset.
     * @type {Array<ApiAssetsExternalExternalIdGet200ResponseAuthorsInner>}
     * @memberof ApiAssetsExternalExternalIdGet200Response
     */
    authors?: Array<ApiAssetsExternalExternalIdGet200ResponseAuthorsInner>;
}

/**
 * Check if a given object implements the ApiAssetsExternalExternalIdGet200Response interface.
 */
export function instanceOfApiAssetsExternalExternalIdGet200Response(value: object): value is ApiAssetsExternalExternalIdGet200Response {
    return true;
}

export function ApiAssetsExternalExternalIdGet200ResponseFromJSON(json: any): ApiAssetsExternalExternalIdGet200Response {
    return ApiAssetsExternalExternalIdGet200ResponseFromJSONTyped(json, false);
}

export function ApiAssetsExternalExternalIdGet200ResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApiAssetsExternalExternalIdGet200Response {
    if (json == null) {
        return json;
    }
    return {
        
        'url': json['url'] == null ? undefined : json['url'],
        'hash': json['hash'] == null ? undefined : json['hash'],
        'authors': json['authors'] == null ? undefined : ((json['authors'] as Array<any>).map(ApiAssetsExternalExternalIdGet200ResponseAuthorsInnerFromJSON)),
    };
}

  export function ApiAssetsExternalExternalIdGet200ResponseToJSON(json: any): ApiAssetsExternalExternalIdGet200Response {
      return ApiAssetsExternalExternalIdGet200ResponseToJSONTyped(json, false);
  }

  export function ApiAssetsExternalExternalIdGet200ResponseToJSONTyped(value?: ApiAssetsExternalExternalIdGet200Response | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'url': value['url'],
        'hash': value['hash'],
        'authors': value['authors'] == null ? undefined : ((value['authors'] as Array<any>).map(ApiAssetsExternalExternalIdGet200ResponseAuthorsInnerToJSON)),
    };
}

