import React, { useEffect, useState } from 'react';
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
  useNavigate,
  Link,
} from 'react-router-dom';
import { Provider, ErrorBoundary } from '@rollbar/react'; // Provider imports 'rollbar'
import 'components-lib/src/styles.css';
import './App.css';
import { Tabs, DIRECTORY_PATH } from 'components-lib';
import Directory from './components/Directory';

const rollbarConfig = {
  accessToken: process.env.REACT_APP_ROLLBAR_ACCESS_TOKEN,
  captureUncaught: true,
  captureUnhandledRejections: true,
  environment: process.env.NODE_ENV || 'testenv',
  autoInstrument: {
    log: true, // Capture console.log
    network: true, // Capture XHR & fetch
    dom: true, // Capture DOM events (e.g., clicks)
    navigation: true, // Capture navigation events
    connectivity: true, // Capture changes in network connectivity
  },
};

const App: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  useEffect(() => {
    if (location.pathname === '/') {
      navigate(DIRECTORY_PATH); // make DIRECTORY_TAB the default tab
    } else if (location.pathname.toUpperCase().startsWith(`${DIRECTORY_PATH}/`)) {
      const canonicalPath = location.pathname.toUpperCase();
      if (location.pathname !== canonicalPath) {
        navigate(canonicalPath); // redirect to uppercase path
      }
    }
  }, [location.pathname]);

  return (
    <div className="app">
      <div className="tabs-container">
        <div className="inner-tabs-container">
          <div className="menu">
            <Link to={process.env.REACT_APP_HOME_URL!} className="title">
              <img src="/images/SureMarkLogo.png" alt="SureMark Digital" />
            </Link>
            <div className="hamburger" onClick={toggleMenu}>
              <div className="bar"></div>
              <div className="bar"></div>
              <div className="bar"></div>
            </div>
            <Tabs isMenuOpen={isMenuOpen} userData={null} />
          </div>
        </div>
      </div>
      <div className="wrapper">
        <div className="content">
          <Routes>
            <Route path={`${DIRECTORY_PATH}/:code?`} element={<Directory />} />
          </Routes>
        </div>
        <p className="footer">© 2024 SureMark Digital. All rights reserved.</p>
      </div>
    </div>
  );
};

export default function WrappedApp() {
  return (
    <Provider config={rollbarConfig}>
      <ErrorBoundary>
        <Router>
          <App />
        </Router>
      </ErrorBoundary>
    </Provider>
  );
}
