import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
interface TabsProps {
  isMenuOpen: boolean;
  userData: { email: string } | null;
}

const Tabs: React.FC<TabsProps> = ({ isMenuOpen, userData }) => {
  const navigate = useNavigate();

  return (
    <>
      {userData?.email && <div className="logged-in-user-info">{userData.email}</div>}
      <div className={`tabs ${isMenuOpen ? 'active' : ''}`}>
        <Link to={APPLY_PATH}>{APPLY_TAB}</Link>
        <Link to={DIRECTORY_PATH} onClick={() => navigate(DIRECTORY_PATH)}>
          {DIRECTORY_TAB}
        </Link>
        <Link to={ABOUT_PATH}>{ABOUT_TAB}</Link>
        <Link to={FAQ_PATH}>{FAQ_TAB}</Link>
        <Link to={NEWS_PATH}>{NEWS_TAB}</Link>
        <Link to={CONTACT_PATH}>{CONTACT_TAB}</Link>
      </div>
    </>
  );
};

export const ABOUT_TAB = 'ABOUT';
export const APPLY_TAB = 'APPLY';
export const DIRECTORY_TAB = 'DIRECTORY';
export const NEWS_TAB = 'NEWS';
export const FAQ_TAB = 'FAQ';
export const CONTACT_TAB = 'CONTACT';
export const ABOUT_PATH = process.env.REACT_APP_ABOUT_URL!;
export const APPLY_PATH = process.env.REACT_APP_APPLY_URL!;
export const DIRECTORY_PATH = process.env.REACT_APP_DIRECTORY_URL!;
export const NEWS_PATH = process.env.REACT_APP_NEWS_URL!;
export const FAQ_PATH = process.env.REACT_APP_FAQ_URL!;
export const CONTACT_PATH = process.env.REACT_APP_CONTACT_URL!;
export default Tabs;
