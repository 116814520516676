/* tslint:disable */
/* eslint-disable */
/**
 * API Documentation
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ApiMySignatureRequestsSignPostRequestClaimClaimSuremark } from './ApiMySignatureRequestsSignPostRequestClaimClaimSuremark';
import {
    ApiMySignatureRequestsSignPostRequestClaimClaimSuremarkFromJSON,
    ApiMySignatureRequestsSignPostRequestClaimClaimSuremarkFromJSONTyped,
    ApiMySignatureRequestsSignPostRequestClaimClaimSuremarkToJSON,
    ApiMySignatureRequestsSignPostRequestClaimClaimSuremarkToJSONTyped,
} from './ApiMySignatureRequestsSignPostRequestClaimClaimSuremark';
import type { ApiMySignatureRequestsSignPostRequestClaimClaimSubject } from './ApiMySignatureRequestsSignPostRequestClaimClaimSubject';
import {
    ApiMySignatureRequestsSignPostRequestClaimClaimSubjectFromJSON,
    ApiMySignatureRequestsSignPostRequestClaimClaimSubjectFromJSONTyped,
    ApiMySignatureRequestsSignPostRequestClaimClaimSubjectToJSON,
    ApiMySignatureRequestsSignPostRequestClaimClaimSubjectToJSONTyped,
} from './ApiMySignatureRequestsSignPostRequestClaimClaimSubject';

/**
 * The claim object containing metadata, subject, and payload.
 * @export
 * @interface ApiMySignatureRequestsSignPostRequestClaimClaim
 */
export interface ApiMySignatureRequestsSignPostRequestClaimClaim {
    /**
     * 
     * @type {ApiMySignatureRequestsSignPostRequestClaimClaimSuremark}
     * @memberof ApiMySignatureRequestsSignPostRequestClaimClaim
     */
    suremark?: ApiMySignatureRequestsSignPostRequestClaimClaimSuremark;
    /**
     * 
     * @type {ApiMySignatureRequestsSignPostRequestClaimClaimSubject}
     * @memberof ApiMySignatureRequestsSignPostRequestClaimClaim
     */
    subject?: ApiMySignatureRequestsSignPostRequestClaimClaimSubject;
    /**
     * A generic JSON object representing the payload of the claim.
     * @type {{ [key: string]: any; }}
     * @memberof ApiMySignatureRequestsSignPostRequestClaimClaim
     */
    payload?: { [key: string]: any; };
}

/**
 * Check if a given object implements the ApiMySignatureRequestsSignPostRequestClaimClaim interface.
 */
export function instanceOfApiMySignatureRequestsSignPostRequestClaimClaim(value: object): value is ApiMySignatureRequestsSignPostRequestClaimClaim {
    return true;
}

export function ApiMySignatureRequestsSignPostRequestClaimClaimFromJSON(json: any): ApiMySignatureRequestsSignPostRequestClaimClaim {
    return ApiMySignatureRequestsSignPostRequestClaimClaimFromJSONTyped(json, false);
}

export function ApiMySignatureRequestsSignPostRequestClaimClaimFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApiMySignatureRequestsSignPostRequestClaimClaim {
    if (json == null) {
        return json;
    }
    return {
        
        'suremark': json['suremark'] == null ? undefined : ApiMySignatureRequestsSignPostRequestClaimClaimSuremarkFromJSON(json['suremark']),
        'subject': json['subject'] == null ? undefined : ApiMySignatureRequestsSignPostRequestClaimClaimSubjectFromJSON(json['subject']),
        'payload': json['payload'] == null ? undefined : json['payload'],
    };
}

  export function ApiMySignatureRequestsSignPostRequestClaimClaimToJSON(json: any): ApiMySignatureRequestsSignPostRequestClaimClaim {
      return ApiMySignatureRequestsSignPostRequestClaimClaimToJSONTyped(json, false);
  }

  export function ApiMySignatureRequestsSignPostRequestClaimClaimToJSONTyped(value?: ApiMySignatureRequestsSignPostRequestClaimClaim | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'suremark': ApiMySignatureRequestsSignPostRequestClaimClaimSuremarkToJSON(value['suremark']),
        'subject': ApiMySignatureRequestsSignPostRequestClaimClaimSubjectToJSON(value['subject']),
        'payload': value['payload'],
    };
}

