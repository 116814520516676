/* tslint:disable */
/* eslint-disable */
/**
 * API Documentation
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ApiMybookingsPostRequest
 */
export interface ApiMybookingsPostRequest {
    /**
     * The date of the booking in ISO 8601 format.
     * @type {Date}
     * @memberof ApiMybookingsPostRequest
     */
    date?: Date;
    /**
     * The ID of the submission
     * @type {string}
     * @memberof ApiMybookingsPostRequest
     */
    submissionId?: string;
    /**
     * The ID of the contact associated with the booking.
     * @type {string}
     * @memberof ApiMybookingsPostRequest
     */
    contactId?: string;
}

/**
 * Check if a given object implements the ApiMybookingsPostRequest interface.
 */
export function instanceOfApiMybookingsPostRequest(value: object): value is ApiMybookingsPostRequest {
    return true;
}

export function ApiMybookingsPostRequestFromJSON(json: any): ApiMybookingsPostRequest {
    return ApiMybookingsPostRequestFromJSONTyped(json, false);
}

export function ApiMybookingsPostRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApiMybookingsPostRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'date': json['date'] == null ? undefined : (new Date(json['date'])),
        'submissionId': json['submissionId'] == null ? undefined : json['submissionId'],
        'contactId': json['contactId'] == null ? undefined : json['contactId'],
    };
}

  export function ApiMybookingsPostRequestToJSON(json: any): ApiMybookingsPostRequest {
      return ApiMybookingsPostRequestToJSONTyped(json, false);
  }

  export function ApiMybookingsPostRequestToJSONTyped(value?: ApiMybookingsPostRequest | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'date': value['date'] == null ? undefined : ((value['date']).toISOString()),
        'submissionId': value['submissionId'],
        'contactId': value['contactId'],
    };
}

