/* tslint:disable */
/* eslint-disable */
/**
 * API Documentation
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ApiYoutubePost200Response
 */
export interface ApiYoutubePost200Response {
    /**
     * 
     * @type {string}
     * @memberof ApiYoutubePost200Response
     */
    message?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ApiYoutubePost200Response
     */
    success?: boolean;
}

/**
 * Check if a given object implements the ApiYoutubePost200Response interface.
 */
export function instanceOfApiYoutubePost200Response(value: object): value is ApiYoutubePost200Response {
    return true;
}

export function ApiYoutubePost200ResponseFromJSON(json: any): ApiYoutubePost200Response {
    return ApiYoutubePost200ResponseFromJSONTyped(json, false);
}

export function ApiYoutubePost200ResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApiYoutubePost200Response {
    if (json == null) {
        return json;
    }
    return {
        
        'message': json['message'] == null ? undefined : json['message'],
        'success': json['success'] == null ? undefined : json['success'],
    };
}

  export function ApiYoutubePost200ResponseToJSON(json: any): ApiYoutubePost200Response {
      return ApiYoutubePost200ResponseToJSONTyped(json, false);
  }

  export function ApiYoutubePost200ResponseToJSONTyped(value?: ApiYoutubePost200Response | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'message': value['message'],
        'success': value['success'],
    };
}

