/* tslint:disable */
/* eslint-disable */
/**
 * API Documentation
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ApiAssetsExternalExternalIdGet200ResponseAuthorsInner
 */
export interface ApiAssetsExternalExternalIdGet200ResponseAuthorsInner {
    /**
     * The unique code of the author.
     * @type {string}
     * @memberof ApiAssetsExternalExternalIdGet200ResponseAuthorsInner
     */
    authorCode?: string;
}

/**
 * Check if a given object implements the ApiAssetsExternalExternalIdGet200ResponseAuthorsInner interface.
 */
export function instanceOfApiAssetsExternalExternalIdGet200ResponseAuthorsInner(value: object): value is ApiAssetsExternalExternalIdGet200ResponseAuthorsInner {
    return true;
}

export function ApiAssetsExternalExternalIdGet200ResponseAuthorsInnerFromJSON(json: any): ApiAssetsExternalExternalIdGet200ResponseAuthorsInner {
    return ApiAssetsExternalExternalIdGet200ResponseAuthorsInnerFromJSONTyped(json, false);
}

export function ApiAssetsExternalExternalIdGet200ResponseAuthorsInnerFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApiAssetsExternalExternalIdGet200ResponseAuthorsInner {
    if (json == null) {
        return json;
    }
    return {
        
        'authorCode': json['author_code'] == null ? undefined : json['author_code'],
    };
}

  export function ApiAssetsExternalExternalIdGet200ResponseAuthorsInnerToJSON(json: any): ApiAssetsExternalExternalIdGet200ResponseAuthorsInner {
      return ApiAssetsExternalExternalIdGet200ResponseAuthorsInnerToJSONTyped(json, false);
  }

  export function ApiAssetsExternalExternalIdGet200ResponseAuthorsInnerToJSONTyped(value?: ApiAssetsExternalExternalIdGet200ResponseAuthorsInner | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'author_code': value['authorCode'],
    };
}

