/* tslint:disable */
/* eslint-disable */
/**
 * API Documentation
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ApiTweetsIdSignaturesGet200ResponseInnerAssetSignaturesInner
 */
export interface ApiTweetsIdSignaturesGet200ResponseInnerAssetSignaturesInner {
    /**
     * The asset signature.
     * @type {string}
     * @memberof ApiTweetsIdSignaturesGet200ResponseInnerAssetSignaturesInner
     */
    assetSignature?: string;
    /**
     * The author code associated with the signature.
     * @type {string}
     * @memberof ApiTweetsIdSignaturesGet200ResponseInnerAssetSignaturesInner
     */
    authorCode?: string;
}

/**
 * Check if a given object implements the ApiTweetsIdSignaturesGet200ResponseInnerAssetSignaturesInner interface.
 */
export function instanceOfApiTweetsIdSignaturesGet200ResponseInnerAssetSignaturesInner(value: object): value is ApiTweetsIdSignaturesGet200ResponseInnerAssetSignaturesInner {
    return true;
}

export function ApiTweetsIdSignaturesGet200ResponseInnerAssetSignaturesInnerFromJSON(json: any): ApiTweetsIdSignaturesGet200ResponseInnerAssetSignaturesInner {
    return ApiTweetsIdSignaturesGet200ResponseInnerAssetSignaturesInnerFromJSONTyped(json, false);
}

export function ApiTweetsIdSignaturesGet200ResponseInnerAssetSignaturesInnerFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApiTweetsIdSignaturesGet200ResponseInnerAssetSignaturesInner {
    if (json == null) {
        return json;
    }
    return {
        
        'assetSignature': json['asset_signature'] == null ? undefined : json['asset_signature'],
        'authorCode': json['author_code'] == null ? undefined : json['author_code'],
    };
}

  export function ApiTweetsIdSignaturesGet200ResponseInnerAssetSignaturesInnerToJSON(json: any): ApiTweetsIdSignaturesGet200ResponseInnerAssetSignaturesInner {
      return ApiTweetsIdSignaturesGet200ResponseInnerAssetSignaturesInnerToJSONTyped(json, false);
  }

  export function ApiTweetsIdSignaturesGet200ResponseInnerAssetSignaturesInnerToJSONTyped(value?: ApiTweetsIdSignaturesGet200ResponseInnerAssetSignaturesInner | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'asset_signature': value['assetSignature'],
        'author_code': value['authorCode'],
    };
}

