/* tslint:disable */
/* eslint-disable */
/**
 * API Documentation
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * Metadata for the claim.
 * @export
 * @interface ApiMySignatureRequestsSignPostRequestClaimClaimSuremark
 */
export interface ApiMySignatureRequestsSignPostRequestClaimClaimSuremark {
    /**
     * The version of the claim format.
     * @type {number}
     * @memberof ApiMySignatureRequestsSignPostRequestClaimClaimSuremark
     */
    version?: number;
    /**
     * The type of the claim.
     * @type {string}
     * @memberof ApiMySignatureRequestsSignPostRequestClaimClaimSuremark
     */
    type?: string;
}

/**
 * Check if a given object implements the ApiMySignatureRequestsSignPostRequestClaimClaimSuremark interface.
 */
export function instanceOfApiMySignatureRequestsSignPostRequestClaimClaimSuremark(value: object): value is ApiMySignatureRequestsSignPostRequestClaimClaimSuremark {
    return true;
}

export function ApiMySignatureRequestsSignPostRequestClaimClaimSuremarkFromJSON(json: any): ApiMySignatureRequestsSignPostRequestClaimClaimSuremark {
    return ApiMySignatureRequestsSignPostRequestClaimClaimSuremarkFromJSONTyped(json, false);
}

export function ApiMySignatureRequestsSignPostRequestClaimClaimSuremarkFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApiMySignatureRequestsSignPostRequestClaimClaimSuremark {
    if (json == null) {
        return json;
    }
    return {
        
        'version': json['version'] == null ? undefined : json['version'],
        'type': json['type'] == null ? undefined : json['type'],
    };
}

  export function ApiMySignatureRequestsSignPostRequestClaimClaimSuremarkToJSON(json: any): ApiMySignatureRequestsSignPostRequestClaimClaimSuremark {
      return ApiMySignatureRequestsSignPostRequestClaimClaimSuremarkToJSONTyped(json, false);
  }

  export function ApiMySignatureRequestsSignPostRequestClaimClaimSuremarkToJSONTyped(value?: ApiMySignatureRequestsSignPostRequestClaimClaimSuremark | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'version': value['version'],
        'type': value['type'],
    };
}

