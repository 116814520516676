/* tslint:disable */
/* eslint-disable */
/**
 * API Documentation
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ApiMySignatureRequestsSignPost200Response
 */
export interface ApiMySignatureRequestsSignPost200Response {
    /**
     * A success message
     * @type {string}
     * @memberof ApiMySignatureRequestsSignPost200Response
     */
    message?: string;
}

/**
 * Check if a given object implements the ApiMySignatureRequestsSignPost200Response interface.
 */
export function instanceOfApiMySignatureRequestsSignPost200Response(value: object): value is ApiMySignatureRequestsSignPost200Response {
    return true;
}

export function ApiMySignatureRequestsSignPost200ResponseFromJSON(json: any): ApiMySignatureRequestsSignPost200Response {
    return ApiMySignatureRequestsSignPost200ResponseFromJSONTyped(json, false);
}

export function ApiMySignatureRequestsSignPost200ResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApiMySignatureRequestsSignPost200Response {
    if (json == null) {
        return json;
    }
    return {
        
        'message': json['message'] == null ? undefined : json['message'],
    };
}

  export function ApiMySignatureRequestsSignPost200ResponseToJSON(json: any): ApiMySignatureRequestsSignPost200Response {
      return ApiMySignatureRequestsSignPost200ResponseToJSONTyped(json, false);
  }

  export function ApiMySignatureRequestsSignPost200ResponseToJSONTyped(value?: ApiMySignatureRequestsSignPost200Response | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'message': value['message'],
    };
}

