/* tslint:disable */
/* eslint-disable */
/**
 * API Documentation
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SocialMediaAccount
 */
export interface SocialMediaAccount {
    /**
     * 
     * @type {string}
     * @memberof SocialMediaAccount
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof SocialMediaAccount
     */
    url?: string;
}

/**
 * Check if a given object implements the SocialMediaAccount interface.
 */
export function instanceOfSocialMediaAccount(value: object): value is SocialMediaAccount {
    return true;
}

export function SocialMediaAccountFromJSON(json: any): SocialMediaAccount {
    return SocialMediaAccountFromJSONTyped(json, false);
}

export function SocialMediaAccountFromJSONTyped(json: any, ignoreDiscriminator: boolean): SocialMediaAccount {
    if (json == null) {
        return json;
    }
    return {
        
        'name': json['name'] == null ? undefined : json['name'],
        'url': json['url'] == null ? undefined : json['url'],
    };
}

  export function SocialMediaAccountToJSON(json: any): SocialMediaAccount {
      return SocialMediaAccountToJSONTyped(json, false);
  }

  export function SocialMediaAccountToJSONTyped(value?: SocialMediaAccount | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'name': value['name'],
        'url': value['url'],
    };
}

