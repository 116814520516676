/* tslint:disable */
/* eslint-disable */
/**
 * API Documentation
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ApiMybookingsPost201Response
 */
export interface ApiMybookingsPost201Response {
    /**
     * 
     * @type {string}
     * @memberof ApiMybookingsPost201Response
     */
    message?: string;
    /**
     * The unique ID of the created booking.
     * @type {string}
     * @memberof ApiMybookingsPost201Response
     */
    bookingId?: string;
}

/**
 * Check if a given object implements the ApiMybookingsPost201Response interface.
 */
export function instanceOfApiMybookingsPost201Response(value: object): value is ApiMybookingsPost201Response {
    return true;
}

export function ApiMybookingsPost201ResponseFromJSON(json: any): ApiMybookingsPost201Response {
    return ApiMybookingsPost201ResponseFromJSONTyped(json, false);
}

export function ApiMybookingsPost201ResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApiMybookingsPost201Response {
    if (json == null) {
        return json;
    }
    return {
        
        'message': json['message'] == null ? undefined : json['message'],
        'bookingId': json['booking_id'] == null ? undefined : json['booking_id'],
    };
}

  export function ApiMybookingsPost201ResponseToJSON(json: any): ApiMybookingsPost201Response {
      return ApiMybookingsPost201ResponseToJSONTyped(json, false);
  }

  export function ApiMybookingsPost201ResponseToJSONTyped(value?: ApiMybookingsPost201Response | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'message': value['message'],
        'booking_id': value['bookingId'],
    };
}

