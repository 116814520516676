/* tslint:disable */
/* eslint-disable */
/**
 * API Documentation
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ApiAssetsHashClaimsGet200ResponseAssetClaimsInner } from './ApiAssetsHashClaimsGet200ResponseAssetClaimsInner';
import {
    ApiAssetsHashClaimsGet200ResponseAssetClaimsInnerFromJSON,
    ApiAssetsHashClaimsGet200ResponseAssetClaimsInnerFromJSONTyped,
    ApiAssetsHashClaimsGet200ResponseAssetClaimsInnerToJSON,
    ApiAssetsHashClaimsGet200ResponseAssetClaimsInnerToJSONTyped,
} from './ApiAssetsHashClaimsGet200ResponseAssetClaimsInner';

/**
 * 
 * @export
 * @interface ApiAssetsHashClaimsGet200Response
 */
export interface ApiAssetsHashClaimsGet200Response {
    /**
     * An array of asset claims.
     * @type {Array<ApiAssetsHashClaimsGet200ResponseAssetClaimsInner>}
     * @memberof ApiAssetsHashClaimsGet200Response
     */
    assetClaims?: Array<ApiAssetsHashClaimsGet200ResponseAssetClaimsInner>;
}

/**
 * Check if a given object implements the ApiAssetsHashClaimsGet200Response interface.
 */
export function instanceOfApiAssetsHashClaimsGet200Response(value: object): value is ApiAssetsHashClaimsGet200Response {
    return true;
}

export function ApiAssetsHashClaimsGet200ResponseFromJSON(json: any): ApiAssetsHashClaimsGet200Response {
    return ApiAssetsHashClaimsGet200ResponseFromJSONTyped(json, false);
}

export function ApiAssetsHashClaimsGet200ResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApiAssetsHashClaimsGet200Response {
    if (json == null) {
        return json;
    }
    return {
        
        'assetClaims': json['asset_claims'] == null ? undefined : ((json['asset_claims'] as Array<any>).map(ApiAssetsHashClaimsGet200ResponseAssetClaimsInnerFromJSON)),
    };
}

  export function ApiAssetsHashClaimsGet200ResponseToJSON(json: any): ApiAssetsHashClaimsGet200Response {
      return ApiAssetsHashClaimsGet200ResponseToJSONTyped(json, false);
  }

  export function ApiAssetsHashClaimsGet200ResponseToJSONTyped(value?: ApiAssetsHashClaimsGet200Response | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'asset_claims': value['assetClaims'] == null ? undefined : ((value['assetClaims'] as Array<any>).map(ApiAssetsHashClaimsGet200ResponseAssetClaimsInnerToJSON)),
    };
}

